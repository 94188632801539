import '../components/styles/sidebarStyles.css'

import { ActionDiv, Login, OverlapContainer } from 'lib'
import { AdminState, AssignState, ServiceState } from '../utils/app.util'
import { Button, Drawer, Fab, FormControlLabel, Paper, Switch, Tooltip } from '@material-ui/core'
import { ChatBubbleOutline as ChatIcon, Close as ClosedIcon, Widgets } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { auth, checkLogin, functions, getChatRef, login, logout, userID } from '../utils/firebase.util'
import { useIsMobile, useWindowDimensions } from '../utils/hooks'

import AcceptDenyForm from '../components/forms/AcceptDenyForm'
import AuthIncreaseForm from '../components/forms/AuthIncreaseForm'
import Chat from 'lib/Chat'
import Countdown from 'react-countdown-now'
import EstimatorSidebar from '../components/EstimatorSidebar'
import InvoiceAuthSelect from '../components/forms/InvoiceAuthSelect'
import InvoiceForm from '../components/forms/InvoiceForm'
import Navbar from '../components/Navbar'
import PropTypes from 'prop-types'
import QuoteDetails from '../components/QuoteDetails'
import QuoteForm from '../components/forms/QuoteForm'
import QuoteMaps from '../components/QuoteMaps'
import VideoSelect from '../components/VideoSelect'
import _ from 'lodash'
import { makeStyles } from '@material-ui/styles'

const ChatContainer = ({ open, onClose, children }) => {
  const cn = useStyles()
  const mobile = useIsMobile()
  const { height, width } = useWindowDimensions()

  return (
    mobile
      ? <Drawer
        ModalProps={{ keepMounted: true }}
        PaperProps={{ className: cn.chatDrawerPaper }}
        onClose={onClose}
        open={open}>
        <div style={{ height, width, display: 'flex' }}>
          {children}
        </div>
        <Fab
          className={cn.closeIconBtn}
          onClick={onClose}><ClosedIcon /></Fab>
      </Drawer>
      : <Paper elevation={1} className={cn.chatPaper}>
        {children}
      </Paper>
  )
}

const ReviewQuoteMain = ({ details }) => {
  const cn = useStyles()
  const mobile = useIsMobile()
  const { proID, assigns, videos, orderID } = details
  const assign = assigns[proID]
  const expiresAt = details.state === 'MOVER_REVIEW' ? details.expireTime : null

  // const [acceptFormOpen, setAcceptFormOpen] = useState(true)
  const [quoteFormOpen, setQuoteFormOpen] = useState(false)
  const [invoiceFormOpen, setInvoiceFormOpen] = useState(false)
  const [authIncFormOpen, setAuthIncFormOpen] = useState(false)
  const [loggedIn, setLoggedIn] = useState(null)
  const [mobileChatOpen, setMobileChatOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showAdminChat, setShowAdminChat] = useState(false)

  useEffect(() => {
    checkLogin().then(res => setLoggedIn(!!res))
  }, [])

  const renderCountdown = ({ days, hours, minutes }) => (
    <span className={cn.countdown}>{days * 24 + hours} hours {minutes} min</span>
  )

  const onDisplayEstimatorBar = (e) => {
    setShowModal(!showModal)
  }

  const handleChangeChat = (event) => {
    setShowAdminChat(event.target.checked)
  }

  const currentChatId = details.chats[showAdminChat ? 'admin' : proID]

  return (
    <div className="App">
      <Navbar
        title={
            assign.state === AssignState.assigned
              ? 'Submit Quote'
              : assign.state === AssignState.quoted
                ? 'Customer In Review'
                : assign.state === AssignState.providerExpired
                  ? 'Expired'
                  : assign.state === AssignState.canceled
                    ? 'Service Canceled'
                    : assign.state === AssignState.booked
                      ? 'Booked Move'
                      : ''
        }
        orderID={details.orderID}
      />
      <div className={cn.pageSection}>
        {mobile && <Button
          variant="outlined"
          className={cn.openChatBtn}
          endIcon={<ChatIcon/>}
          onClick={() => setMobileChatOpen(true)}
        >Customer Chat</Button>}
        <Paper className={cn.header} elevation={1}>
          {orderID && <div className={cn.idText}>ID: {orderID}</div>}
          {expiresAt &&
            <div className={cn.countdown}>
              <span>Expires in: </span>
              <Countdown date={expiresAt} renderer={renderCountdown} />
            </div>
          }
        </Paper>
      </div>
      <div className={cn.floatingContainer}>
        <Tooltip title="Show Estimator">
          <Button
            variant="contained"
            className={cn.floatingButton}
            onClick={() => onDisplayEstimatorBar()}
          >
            <Widgets />
          </Button>
        </Tooltip>
      </div>
      <EstimatorSidebar orderID={details.orderID} initialData={details.estimator} showModal={showModal} setShowModal={setShowModal} />
      <div className={cn.pageSection}>
        <div className={cn.pageColumn}>
          <Paper className={cn.videoContainer} elevation={1}>
            <VideoSelect videos={videos} orderID={orderID} />
          </Paper>
          <QuoteDetails details={details} />
          <QuoteMaps details={details} />
        </div>
        <div className={cn.spacer} />
        <div className={cn.pageColumn}>
          <div className={cn.buttonsContainer}>
            <span>
              {
                details.hasMoverAddedToAdminChat && loggedIn && <FormControlLabel
                  control={
                    <Switch
                      name="checkedB"
                      color="primary"
                      onChange={handleChangeChat}
                      checked={showAdminChat}
                    />
                  }
                  label="Switch to general chat"
                />
              }
            </span>
            <span>
              {
                loggedIn && <Button
                    onClick={logout}
                  >Logout</Button>
              }
            </span>
          </div>
          <ChatContainer
            open={mobileChatOpen}
            onClose={() => setMobileChatOpen(false)}>
            {!loggedIn
              ? <div className={cn.login}>
                <Login auth={auth} login={login} onLogin={setLoggedIn} loginLabel={'Login For Chat'}/>
              </div>
              : <Chat
                  key={currentChatId}
                  userID={userID()}
                  chatRef={getChatRef(currentChatId)}
                  functions={functions}
                />
            }
          </ChatContainer>
          {(assign.state === AssignState.assigned ||
            assign.state === AssignState.quoted ||
            assign.state === AssignState.booked ||
            assign.state === AssignState.customerExpired) &&
          (details.state === AdminState.review ||
            details.state === AdminState.booked) &&
          !_.some(details.services, s => s.state === ServiceState.authorized) &&
            <OverlapContainer className={cn.quoteForm}>
              <ActionDiv fade={quoteFormOpen}>
                <AcceptDenyForm onOpenForm={() => setQuoteFormOpen(true)} details={details}/>
              </ActionDiv>
              <ActionDiv
                collapse={!quoteFormOpen}
                onClose={() => setQuoteFormOpen(false)}>
                <QuoteForm details={details}/>
              </ActionDiv>
            </OverlapContainer>
          }
          {_.filter(details.services, s => s.state === ServiceState.awaitInvoice).map((s, key) =>
            <OverlapContainer className={cn.quoteForm} key={key}>
              <ActionDiv fade={invoiceFormOpen || authIncFormOpen}>
                <InvoiceAuthSelect
                  onOpenAuthInc={() => setAuthIncFormOpen(true)}
                  oneOpenInvoice={() => setInvoiceFormOpen(true)}/>
              </ActionDiv>
              <ActionDiv collapse={!authIncFormOpen} onClose={() => setAuthIncFormOpen(false)}>
                <AuthIncreaseForm details={details} service={s}/>
              </ActionDiv>
              <ActionDiv collapse={!invoiceFormOpen} onClose={() => setInvoiceFormOpen(false)}>
                <InvoiceForm details={details} service={s}/>
              </ActionDiv>
            </OverlapContainer>
          )}
        </div>
      </div>
    </div>
  )
}

ReviewQuoteMain.propTypes = {
  details: PropTypes.any.isRequired
}

const useStyles = makeStyles({
  pageSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '4% 2% 0',
    boxSizing: 'border-box',
    '@media only screen and (max-width: 600px)': {
      width: '100%',
      flexDirection: 'column-reverse',
      alignItems: 'center'
    }
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBlock: '3px'
  },
  header: {
    padding: '10px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  idText: {
    fontSize: 23
  },
  countdown: {
    paddingTop: 7
  },
  pageColumn: {
    width: 'min-content',
    minWidth: 450,
    '@media only screen and (max-width: 1100px)': {
      minWidth: 'unset',
      width: '49%'
    },
    '@media only screen and (max-width: 600px)': {
      minWidth: 'unset',
      width: '100%'
    }
  },
  spacer: {
    minWidth: '2%',
    '@media only screen and (max-width: 1100px)': {
      minWidth: 15
    }
  },
  login: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  videoContainer: {
    width: '100%',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
    '@media only screen and (max-width: 600px)': {
      padding: 5
    }
  },
  quoteForm: {
    width: '100%',
    maxWidth: 450,
    marginBottom: 30,
    '@media only screen and (max-width: 600px)': {
      width: '100%',
      maxWidth: 'unset'
    }
  },
  openChatBtn: {
    marginTop: 10
  },
  closeIconBtn: {
    position: 'absolute',
    top: 20,
    left: 20,
    background: 'white'
  },
  chatPaper: {
    extend: 'quoteForm',
    height: 400,
    display: 'flex'
  },
  chatDrawerPaper: {
    overflowY: 'unset !important'
  },
  floatingContainer: {
    position: 'fixed',
    right: '10px',
    top: '100px'
  },
  floatingButton: {
    backgroundColor: '#0E75BB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#78A3C0'
    },
    cursor: 'pointer'
  }
})

export default ReviewQuoteMain
