import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth'
import 'firebase/compat/functions'

import { URLParams, reload } from './app.util'

/* eslint-disable no-console */
import firebase from 'firebase/compat/app'

console.log(process.env)

const devConfig = {
  apiKey: 'AIzaSyAx-i3luVOn2d4iIFc1R9hCvC6XVWIaP08',
  authDomain: 'muva-backend-dev.firebaseapp.com',
  databaseURL: 'https://muva-backend-dev.firebaseio.com',
  projectId: 'muva-backend-dev',
  storageBucket: 'muva-backend-dev.appspot.com',
  messagingSenderId: '535024031644',
  appId: '1:535024031644:web:a444864250dd26bb'
}

const firebaseConfig = {
  apiKey: 'AIzaSyAqLMsS5QKyaavYDRjqnHwbOWde4M8yZyo',
  authDomain: 'muva-backend.firebaseapp.com',
  databaseURL: 'https://muva-backend.firebaseio.com',
  projectId: 'muva-backend',
  storageBucket: 'muva-backend.appspot.com',
  messagingSenderId: '349722643142',
  appId: '1:349722643142:web:8ea00877c238394c5ba5c7'
}

if (process.env.NODE_ENV === 'development' && !process.env.REACT_APP_STAGE) {
  firebase.initializeApp(devConfig)
} else {
  firebase.initializeApp(firebaseConfig)
}

export const db = firebase.firestore()
export const functions = firebase.functions()
export const storage = firebase.storage()
export const auth = firebase.auth()

// connect to local emulator env
if (process.env.REACT_APP_EMULATE) {
  console.log('use emulators')
  db.useEmulator('0.0.0.0', 5002)
  functions.useEmulator('0.0.0.0', 5001)
  auth.useEmulator('http://0.0.0.0:5005')
}

const cloudFunc = (funcName, params) => functions.httpsCallable(funcName)(params)
const cloudData = (funcName, params) => cloudFunc(funcName, params).then(res => res.data)

/** Login */

const firstAuthStateChange = new Promise((resolve) => {
  auth.onAuthStateChanged(async () => resolve())
})

export const checkLogin = async () => {
  await firstAuthStateChange
  return firebase.auth().currentUser
}

export const login = async (user, pass) => {
  await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  await auth.signInWithEmailAndPassword(user, pass)
  const res = await auth.currentUser.getIdTokenResult()
  if (!res.claims.mover) {
    await auth.signOut()
    throw Error('No mover credentials')
  };
}

export const logout = () => auth.signOut()
  .then(() => reload())
  .catch(error => console.log(error))

export const userID = () => auth.currentUser.uid

export const getChatRef = (chatID) =>
  firebase.firestore().collection('chats').doc(chatID)

/** Mover API */

export const getVideoURL = async (fileName) => {
  try {
    const storageRef = storage.ref().child(fileName)
    storageRef.updateMetadata({ contentDisposition: 'attachment' })
    const url = await storageRef.getDownloadURL()
    return url
  } catch (error) {
    /// /
    // TODO: Error Handling
    /// /
    switch (error.code) {
      case 'storage/object-not-found':
        // File doesn't exist
        break
      case 'storage/unauthorized':
        // User doesn't have permission to access the object
        break

      case 'storage/canceled':
        // User canceled the upload
        break
      case 'storage/unknown':
        // Unknown error occurred, inspect the server response
        break
      default:
        break
    }
    return null
  }
}

const linkID = { linkID: URLParams().linkID }

export const getLinkInfo = () =>
  cloudData('order-getLink', linkID)
export const acceptTerms = async () =>
  cloudFunc('pro-acceptTerms', linkID)
export const moverReject = () =>
  cloudFunc('order-proReject', linkID)
export const moverAccept = (quote, expireDays, paymentMethod) =>
  cloudFunc('order-proAccept', { ...linkID, quote, expireDays, paymentMethod })
export const submitInvoice = (invoicePrice, serviceType, serviceNotes, trackLink) =>
  cloudFunc('order-submitInvoice', { ...linkID, serviceType, invoicePrice, serviceNotes, trackLink })
export const increaseAuth = (maxPrice, serviceType) =>
  cloudFunc('order-proIncAuth', { ...linkID, maxPrice, serviceType })
export const saveEstimator = (payload) =>
  cloudData('order-saveEstimator', payload)

/** Home Page */

export const getMoverInfo = () => cloudData('pro-get')
export const getStripeLink = () => cloudData('pro-getStripeLink')
export const verifyStripeConnect = code => cloudData('pro-verifyStripeConnect', { code })
export const verifyEmail = async (oobCode) => {
  await auth.applyActionCode(oobCode)
  await cloudFunc('pro-emailVerified')()
}

export const sendPasswordReset = () => cloudFunc('pro-sendPasswordReset')

/** DEV */

export const deleteStripeConnect = () => cloudFunc('dev-deleteStripeConnect')

export const estimatorItems = async () => {
  const elementsArray = []
  const elements = await firebase.firestore().collection('estimator').get()
  elements.forEach(doc => {
    elementsArray.push(doc.data())
  })
  return elementsArray
}
