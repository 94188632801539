import { Delete } from '@material-ui/icons'
import NumericInput from './NumericInput'
import React from 'react'
import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '0.25em',
    marginBottom: '0.25em'
  },
  itemContainer: {
    maxWidth: '480px'
  },
  nameSpan: {
    maxWidth: '120px',
    width: '120px',
    marginRight: '10px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  quantityInput: {
    width: '50px',
    border: '1px solid #176BB5',
    borderRadius: '7px',
    marginRight: '10px',
    outline: 'none',
    padding: '5px'
  },
  result: {
    marginLeft: '10px',
    fontWeight: 'bold'
  },
  remove: {
    marginRight: '15px',
    backgroundColor: 'transparent',
    border: 'none',
    height: '22px',
    borderRadius: '50%',
    '&:hover': {
      color: 'blue',
      cursor: 'pointer'
    }
  }
})

export default function EstimatorItem ({ items, setItems, onRemove, onChange }) {
  const styles = useStyles()

  const updateItem = (amount, item) => {
    const updatedItems = items.map((i) =>
      i.name === item.name
        ? { ...i, result: amount * i.cubiFtPerPiece, numberOfItems: amount }
        : i
    )
    setItems(updatedItems)
    if (onChange) onChange(updatedItems)
  }

  return (
    <div className={styles.itemContainer}>
      {items.map((item, index) => (
        <div key={`${item.name}_${index}`} className={styles.flex}>
          <button
            className={styles.remove}
            onClick={() => onRemove(item, index)}
          >
            <Delete fontSize="small" />
          </button>
          <Tooltip title={item.name}>
            <span className={styles.nameSpan}>{item.name}</span>
          </Tooltip>
          <NumericInput
            min={0}
            initialValue={item.numberOfItems ?? 0}
            onChange={(val) => updateItem(val, item)}
          />
          <b>×</b>&nbsp;<span>{item.cubiFtPerPiece} ft³ = </span>
          <span className={styles.result}>{item.result}</span>
        </div>
      ))}
    </div>
  )
}
