import { IconButton, Snackbar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { estimatorItems, saveEstimator } from 'utils/firebase.util'
import { loadEstimatorItems, storeEstimatorItems } from '../utils/app.util'

import ActionButton from './ActionButton'
import Alert from '@material-ui/lab/Alert'
import { Close } from '@material-ui/icons'
import EstimatorItem from './EstimatorItem'
import Select from 'react-select'
import _ from 'lodash'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  separator: { marginTop: '1em' },
  inlineButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '5px'
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

export default function EstimatorSidebar ({
  showModal,
  setShowModal,
  initialData,
  orderID,
  onChange
}) {
  const styles = useStyles()
  const [showAlert, setShowAlert] = useState(false)
  const [categories, setCategories] = useState({})
  const [categorySelected, setCategorySelected] = useState(null)
  const [subCategorySelected, setSubCategorySelected] = useState(null)
  const [selectedItems, setSelectedItems] = useState({})

  useEffect(() => {
    const getEstimatorItems = async () => {
      const items = await estimatorItems()
      const categoriesTemp = items.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.name]: curr.items.map((r) => ({
            ...r,
            value: r.name,
            label: r.name,
            numberOfItems: 0,
            result: 0,
            category: curr.name
          }))
        }),
        {}
      )
      setCategories(categoriesTemp)
    }
    getEstimatorItems()

    const storedItems = loadEstimatorItems()
    if (!_.isEmpty(initialData)) setSelectedItems(initialData)
    else if (storedItems) setSelectedItems(storedItems)
  }, [initialData])

  const handleItemUpdate = (items) => {
    setSelectedItems(items)
    storeEstimatorItems(items)
    if (onChange) onChange(items)
  }

  const onRemoveItem = (item, index) => {
    const updatedItems = { ...selectedItems }
    updatedItems[item.category].items.splice(index, 1)
    if (updatedItems[item.category].items.length === 0) {
      delete updatedItems[item.category]
    }
    handleItemUpdate(updatedItems)
  }

  const handleCategoryChange = (category) => {
    setCategorySelected(category)
    setSubCategorySelected(null) // Limpia el segundo select
  }

  const handleAddItem = () => {
    if (!subCategorySelected) return

    const updatedItems = {
      ...selectedItems,
      [categorySelected.value]: {
        items: [
          ...(selectedItems[categorySelected.value]?.items || []),
          subCategorySelected
        ]
      }
    }

    handleItemUpdate(updatedItems)

    // Limpia ambos selects
    setCategorySelected(null)
    setSubCategorySelected(null)
  }

  const categoryOptions = Object.keys(categories).map((cat) => ({
    value: cat,
    label: cat
  }))

  const subcategoryOptions = categories[categorySelected?.value] || []

  return (
    <div className={showModal ? 'modal-dialog show' : 'modal-dialog'}>
      <div className="modal-content">
        <div className="modal-header">
          <IconButton
            onClick={() => setShowModal(false)}
            size="small"
          >
            <Close />
          </IconButton>
          <div className={styles.headerTitle}>
            <h4>The Muva Estimator</h4>
            <ActionButton
              size="small"
              onClick={async () => {
                const estimator = loadEstimatorItems()
                await saveEstimator({ orderID, estimator })
              }}
              disabled={_.isEmpty(selectedItems)}
            >
              Save changes
            </ActionButton>
          </div>
        </div>
        <div className="modal-body">
          <Select
            options={categoryOptions}
            isClearable
            value={categorySelected}
            onChange={handleCategoryChange}
            placeholder="Select a category"
          />
          <Select
            className={styles.separator}
            options={subcategoryOptions}
            isClearable
            value={subCategorySelected}
            onChange={setSubCategorySelected}
            placeholder="Select an item"
            isDisabled={subcategoryOptions.length === 0}
          />
          <div className={styles.separator}></div>
          <div className={styles.inlineButtons}>
            <ActionButton
              size="small"
              onClick={handleAddItem}
              disabled={!subCategorySelected}
            >
              Add Item
            </ActionButton>
            <ActionButton
              size="small"
              onClick={() => handleItemUpdate({})}
              disabled={_.isEmpty(selectedItems)}
            >
              Clear all
            </ActionButton>
          </div>
          <div className={styles.separator}></div>
          {Object.entries(selectedItems).map(([key, value]) => (
            <div key={key}>
              <h5>{key}</h5>
              <EstimatorItem
                items={value.items}
                setItems={(updatedItems) =>
                  handleItemUpdate({
                    ...selectedItems,
                    [key]: { items: updatedItems }
                  })
                }
                onRemove={onRemoveItem}
                onChange={onChange}
              />
            </div>
          ))}
        </div>
      </div>
      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={() => setShowAlert(false)}
      >
        <Alert onClose={() => setShowAlert(false)} severity="warning">
          This item is already added!
        </Alert>
      </Snackbar>
    </div>
  )
}
